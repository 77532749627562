import React from "react";
import {
  ThemeProvider,
  CssBaseline,
  Button,
  Stack,
  Typography
} from "@mui/material";
import { Container, Box } from "@mui/system";
import { getWhatsAppUrl, phoneNumberValidation } from "../../constants/Helper";
import theme from "../../theme/theme";
import CustomizedInputs from "../customComponent/CustomInput";
import FullScreenDialog from "../../layouts/FullScreenDailog";
import LoginWithPasswordScreen from "./loginWithpassword";
import InputAdornment from '@mui/material/InputAdornment';
import axiosService from "../../axios-api-kit/axiosService";
import { localStorageKey } from "../../constants/LocalStorageKey";
import { appTheme } from "../../theme/colorTheme";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AuthContext } from "../../context/AuthProvider";
import constant from "../../constants/Constatnt";
import whatsAppIcon from '../../img/whatsapp.svg';
import { useSelector } from "react-redux";

const LoginScreen = () => {
  const [searchParams] = useSearchParams();
  const helpLineWhatsAppNumber = useSelector(store => store?.app?.helpLineWhatsAppNumber);
  let navigate = useNavigate();
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [isPhoneNumberValid, setIsPhoneNumberValid] = React.useState(true);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [isUserRegistered, setIsUserRegistered] = React.useState(true);
  const [dailogHeaderText, setDailogHeaderText] = React.useState("");
  const { getDevToolState } = React.useContext(AuthContext);

  const numberRef = React.useRef();

  const handleOpenDialog = (isPhoneNumberValidCheck, phoneNumberCheck) => {
    if (isPhoneNumberValidCheck && phoneNumberCheck.length !== 0) {
      const payload = new URLSearchParams()
      payload.append('phone', phoneNumberCheck)
      axiosService.checkIsUserRegisterd(payload).then((resp) => {
        if (resp?.data?.is_registered) {
          setIsUserRegistered(true);
          setDailogHeaderText("Login");
          setOpenDialog(true);
        }else{
          setIsUserRegistered(false);
        }
      })
        .catch((error) => {
          setOpenDialog(false);
        })

    }

  };
  const openLoginWithPassword = React.useCallback((inputData) => {
      return <LoginWithPasswordScreen data={inputData} />
  }, [])

  const validatePhoneNumberHandler = () => {
    setIsPhoneNumberValid(phoneNumberValidation(phoneNumber))
  };

  const checkUserNameValidation = (value) => {
    if (phoneNumberValidation(value)) {
      setIsPhoneNumberValid(true)
    } else {
      setIsPhoneNumberValid(false)
    }
  }


  const continueToLogin = (phoneNumberCheck) => {
    checkUserNameValidation(phoneNumberCheck);
    handleOpenDialog(isPhoneNumberValid, phoneNumberCheck);
  }


  React.useEffect(() => {
    if(!!searchParams.get(localStorageKey.PUSHID) && localStorage.getItem(localStorageKey.PUSHID) !== searchParams.get(localStorageKey.PUSHID)) {
      localStorage.setItem(localStorageKey.PUSHID, searchParams.get(localStorageKey.PUSHID))
    }
    sessionStorage.removeItem(localStorageKey.SETIDSTABSTATE);
  }, [])


  return (
    <React.Fragment>
      {!!openDialog ? <FullScreenDialog openDialog={openDialog} setOpenDialog={setOpenDialog} headerText={dailogHeaderText} layout={() => openLoginWithPassword({
        enteredMobileNumber: phoneNumber
      }
      )} /> : ''}
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Container
          sx={{
            color: appTheme.color1,
            mt: 10
          }}
        >
          <Stack
            sx={{
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              mb: 10,
            }}
          >
            <Box
              sx={{
                mb: 1,
                width: 50,
                height: 45,
                borderRadius: 2,
                backgroundColor: appTheme.color11,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                style={{
                  width: '120px', borderRadius: 5, backgroundColor: appTheme.color11, padding: 10,
                  justifyContent: "center", alignItems: "center", verticalAlign: 'middle'
                }}
                src={require(`../../img/${constant.logoPath}`)} alt="logo"></img>
            </Box>

          </Stack>
          
          <CustomizedInputs 
          ref={numberRef}
           key={"2"} placeholder="Enter 10 Digits Phone Number"
            label="Phone Number*" defaultValue={phoneNumber}
            type="tel"
            setData={(value) => {
              setPhoneNumber(value.slice(0, 10));
            }}
            isValidation={isPhoneNumberValid}
            checkValidation={(value) => {
              checkUserNameValidation(value.slice(0, 10))
            }}
            onBlur={validatePhoneNumberHandler}
            startAdornment={
              <InputAdornment position="start" sx={{ color: appTheme.color1 }}>
                <img
                  src={require("../../img/icons8-india-48.png")}
                  width="30"
                  alt="logo"
                  loading="lazy"
                />&nbsp;+91
              </InputAdornment>
            }
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                if(!getDevToolState()){
                  continueToLogin(phoneNumber);
                }else{
                  navigate('/devTools')
                }
              }
            }} />
            {
              (!isUserRegistered) && <Typography
              variant="caption"
              display="block"
              sx={{ color: `red`, mt:-1.5, mb:1.5 }}
            >
              The mobile number you entered isn't connected to an account. <span onClick={() => {
                                navigate('/signup')
                                sessionStorage.setItem(localStorageKey.USERNUMBER, phoneNumber)
                            }} style={{ color: appTheme.color14, cursor: 'pointer', fontWeight:"bold", textDecoration: 'underline' }}>
                                Click here to sign up.
                            </span>
            </Typography>
            }
          
          <Stack>
            <Button onClick={() => {
              if(!getDevToolState()){
                continueToLogin(phoneNumber);
              }else{
                navigate('/devTools')
              }
              
            }}
              sx={{
                color: appTheme.color0, bgcolor: appTheme.color12,
                "&:hover": {
                  bgcolor: appTheme.color12
                },
                focusVisible: false,
                pb: 1, pt: 1
              }}>Submit</Button>
              
              <Button onClick={() => {
              window.open(getWhatsAppUrl(helpLineWhatsAppNumber));
            }}
              sx={{
                display:'flex',
                justifyContent:"center",
                textTransform:"capitalize",
                color: appTheme.color1, bgcolor: "#22C55E",
                boxShadow: '0 5px 21px #00cf5875',
                "&:hover": {
                  bgcolor: "#22C55E"
                },
                focusVisible: false,
                pb: 1, pt: 1,
                mt:3,
              }}><img src={whatsAppIcon} alt="Whats app"/>&nbsp;Get Instant ID on Whatsapp</Button>

          </Stack>
        </Container>
      </ThemeProvider>
    </React.Fragment>
  );
};

export default LoginScreen;
