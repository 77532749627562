import React from "react";
import {
    ThemeProvider,
    CssBaseline,
    Typography,
    Stack
} from "@mui/material";
import { Container, Box } from "@mui/system";
import theme from "../theme/theme";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Chip from '@mui/material/Chip';
import { appTheme } from "../theme/colorTheme";
import { getImgSrc } from "../constants/Helper";
import TransactionScreenShotModal from "./transactioScreenShotDailog";

const TransactionDetailsScreen = (props) => {
    const [openInfo, setOpenInfo] = React.useState(false);
    const [screenShotImgSrc, setScreenShotImgSrc] = React.useState('');
    const [screenShotImgWidth, setScreenShotImgWidth] = React.useState('');
    return (
        <React.Fragment>
            {
                openInfo && <TransactionScreenShotModal  openInfo={openInfo}
                setOpenInfo={setOpenInfo}
                imgSrc={screenShotImgSrc}
                width={screenShotImgWidth}/>
            }

            <ThemeProvider theme={theme}>
                <CssBaseline />
                <Container
                    sx={{
                        color: appTheme.color1,
                        mt: 1
                    }}
                >
                    <Stack
                        sx={{
                            alignItems: "center",
                            justifyContent: "center",
                            textAlign: "center",
                            mb: 3,
                        }}
                    >
                        <Box
                            sx={{
                                mb: 1,
                                borderRadius: 2,
                                backgroundColor: appTheme.color11,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                padding: 1
                            }}
                        >
                            <img
                                src={!!getImgSrc(props?.transactionData?.logoUrl) ? getImgSrc(props.transactionData.logoUrl) : require('../img/no-pictures.png')}
                                width="70"
                                alt="logo"
                                loading="lazy"
                            />
                        </Box>

                        <Typography variant="body1" component="div">
                            {!!props?.transactionData?.brandName ? props?.transactionData?.brandName : ''}
                        </Typography>
                        <Typography variant="caption" display="block">
                            {props?.transactionData?.accessUrl}
                        </Typography>
                    </Stack>
                    {
                        props.transactionData.paymentScreenShot.length > 0 &&
                        <Stack sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Typography
                                variant="caption"
                                display="block"
                                gutterBottom
                                sx={{ color: appTheme.color1 }}
                            >
                                Click to view Transactions Screenshot
                            </Typography>
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                {
                                    props.transactionData.paymentScreenShot.map((item) => (
                                        <img
                                            key={item}
                                            src={!!getImgSrc(item) ? getImgSrc(item) : require('../img/no-pictures.png')}
                                            width="50"
                                            alt="logo"
                                            loading="lazy"
                                            style={{
                                                cursor: 'pointer',
                                                borderRadius: 2,
                                                border: `2px solid ${appTheme.color11}`,
                                                padding: .5
                                            }}
                                            onClick={() => {
                                               setScreenShotImgSrc(!!getImgSrc(item) ? getImgSrc(item) : require('../img/no-pictures.png'));
                                               setScreenShotImgWidth('100%');
                                               setOpenInfo(true);
                                            }}
                                        />
                                    ))
                                }
                            </Box>
                        </Stack>
                    }

                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        sx={{ mt: 3, mb: 3, border: `1px dashed ${appTheme.color17}`, padding: 2, backgroundColor: appTheme.color11 }}
                    >
                        <Typography
                            variant="caption"
                            display="block"
                            gutterBottom
                            sx={{ color: `white` }}
                        >
                            {
                                props?.transactionData?.transactionRequest === 'WalletRequest' && props?.transactionData?.transactionType === 'Deposit' ?
                                    'Deposit to wallet' :
                                    props?.transactionData?.transactionRequest === 'ExistingIdRequest' && props?.transactionData?.transactionType === 'Deposit' ?
                                        `Deposit to ${props?.transactionData?.brandName} Id` :
                                        props?.transactionData?.transactionRequest === 'WalletRequest' && props?.transactionData?.transactionType === 'Withdraw' ?
                                            'Withdraw from wallet' :
                                            props?.transactionData?.transactionRequest === 'ExistingIdRequest' && props?.transactionData?.transactionType === 'Withdraw' ?
                                                `Withdraw from ${props?.transactionData?.brandName} Id` :
                                                props?.transactionData?.transactionRequest === 'CloseIdRequest' ? 'Close Id' :
                                                    props?.transactionData?.transactionRequest === 'CreateIdRequest' ?
                                                        `Created ${props?.transactionData?.brandName} Id` :
                                                        props?.transactionData?.transactionRequest === 'ChangePasswordRequest' ? `Changed ${props?.transactionData?.brandName} password` :
                                                            ''
                            }

                        </Typography>
                        <Box>
                            <Chip sx={{ fontSize: 10 }} label={props?.transactionData?.status.toLocaleUpperCase()}
                                color={props?.transactionData?.status.toLocaleLowerCase() === 'approved' ? 'success' :
                                    props?.transactionData?.status.toLocaleLowerCase() === 'verified' ? 'success' :
                                        props?.transactionData?.status.toLocaleLowerCase() === 'rejected' ? 'error' : 'warning'} />
                            {props?.transactionData?.timetaken &&
                                <Typography
                                    variant="caption"
                                    display="block"
                                    gutterBottom
                                    sx={{ color: appTheme.color7 }}
                                >
                                    Time Taken {props.transactionData.timetaken}
                                </Typography>}
                        </Box>

                    </Stack>
                    <TableContainer sx={{ bgcolor: appTheme.color11 }}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ color: appTheme.color1, textAlign: 'center' }} colSpan={2}>
                                        Transaction Details
                                    </TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>

                                <TableRow
                                    key={1}
                                    sx={{ bgcolor: appTheme.color3, '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell sx={{ border: 0, color: appTheme.color1, pb: 1.5, pt: 1.5 }} component="th" scope="row">
                                        <Typography variant="caption" display="block">Coins</Typography>
                                    </TableCell>
                                    <TableCell sx={{ border: 0, color: appTheme.color1, pb: 1.5, pt: 1.5 }} align="right">
                                        <Typography variant="caption" display="block">{props?.transactionData?.amount}</Typography>

                                    </TableCell>
                                </TableRow>

                                <TableRow
                                    key={2}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell sx={{ border: 0, color: appTheme.color1, pb: 1.5, pt: 1.5 }} component="th" scope="row">
                                        <Typography variant="caption" display="block">Reference No.</Typography>

                                    </TableCell>
                                    <TableCell sx={{ border: 0, color: appTheme.color1, pb: 1.5, pt: 1.5 }} align="right">
                                        <Typography variant="caption" display="block">{props?.transactionData?.referenceNo}</Typography>

                                    </TableCell>
                                </TableRow>

                                <TableRow
                                    key={3}
                                    sx={{ bgcolor: appTheme.color3, '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell sx={{ border: 0, color: appTheme.color1, pb: 1.5, pt: 1.5 }} component="th" scope="row">
                                        <Typography variant="caption" display="block">Request Date</Typography>

                                    </TableCell>
                                    <TableCell sx={{ border: 0, color: appTheme.color1, pb: 1.5, pt: 1.5 }} align="right">
                                        <Typography variant="caption" display="block">{props?.transactionData?.requestedDate}</Typography>

                                    </TableCell>
                                </TableRow>

                                <TableRow
                                    key={4}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell sx={{ border: 0, color: appTheme.color1, pb: 1.5, pt: 1.5 }} component="th" scope="row">
                                        <Typography variant="caption" display="block">Started on</Typography>

                                    </TableCell>
                                    <TableCell sx={{ border: 0, color: appTheme.color1, pb: 1.5, pt: 1.5 }} align="right">
                                        <Typography variant="caption" display="block">{props?.transactionData?.startedDate}</Typography>

                                    </TableCell>
                                </TableRow>

                                <TableRow
                                    key={5}
                                    sx={{ bgcolor: appTheme.color3, '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell sx={{ border: 0, color: appTheme.color1, pb: 1.5, pt: 1.5 }} component="th" scope="row">
                                        <Typography variant="caption" display="block">Approved on</Typography>

                                    </TableCell>
                                    <TableCell sx={{ border: 0, color: appTheme.color1, pb: 1.5, pt: 1.5 }} align="right">
                                        <Typography variant="caption" display="block">{props?.transactionData?.approvedOn}</Typography>

                                    </TableCell>
                                </TableRow>

                                <TableRow
                                    key={6}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell sx={{ border: 0, color: appTheme.color1, pb: 1.5, pt: 1.5 }} component="th" scope="row">
                                        <Typography variant="caption" display="block">Remarks</Typography>

                                    </TableCell>
                                    <TableCell sx={{ border: 0, color: appTheme.color1, pb: 1.5, pt: 1.5 }} align="right">
                                        <Typography variant="caption" display="block"> {props?.transactionData?.remarks}</Typography>

                                    </TableCell>
                                </TableRow>
                                <TableRow
                                    key={7}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                </TableRow>

                            </TableBody>
                        </Table>
                    </TableContainer>
                </Container>
            </ThemeProvider>
        </React.Fragment>
    );
};

const TransactionDetails = React.memo(TransactionDetailsScreen);

export default TransactionDetails;