import React, { useRef } from 'react';
import axiosService from '../axios-api-kit/axiosService';
import { useNavigate } from 'react-router-dom';
import { Box, Stack } from '@mui/system';
import { Button, Divider, Typography } from '@mui/material';
import { AuthContext } from '../context/AuthProvider';
import { appTheme } from '../theme/colorTheme';
import SnackBar from './SnackBar';
import { getImgSrc, getRandomColor } from '../constants/Helper';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import constant from '../constants/Constatnt';
import ConstructionOutlinedIcon from '@mui/icons-material/ConstructionOutlined';

const TitlebarImageListScreen = (props) => {
  const navigate = useNavigate();
  const { setCreateIdData } = React.useContext(AuthContext)
  const [websiteData, setWebsiteData] = React.useState(null);
  const [snackBarMessage, setSnackBarMessage] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const colorIdx = useRef(0);
  const getWebsiteData = () => {
    axiosService.getWebsitesData().then((response) => {
      const trimData = response.data.slice(0, constant.numberOfIdsOnHome)
      setWebsiteData(trimData)
    }).catch(error => {
    })
  }
  const handleClick = React.useCallback((message) => {
    setSnackBarMessage(message)
    setOpen(true);
}, []);
  React.useEffect(() => {
    getWebsiteData();
  }, []);
  return (
    <>
    <SnackBar
                setOpen={setOpen}
                open={open}
                message={snackBarMessage}
            />
                           <div style={{display: 'flex', justifyContent: 'space-between', paddingBlock:'1rem'}}>
                           <Box id="createIds">
                                    <Typography variant="body1" gutterBottom>
                                       IDs
                                    </Typography>
                                    <Divider sx={{ borderTop: `1px solid ${appTheme.color12}`, width: "30px" }} />
                                </Box>
                                <Box>
                                    <Button
                                        // startIcon={<AddCircleSharpIcon fontSize="small" />}
                                        onClick={() => {
                                            navigate('/ids?index=1')
                                        }}
                                        size="small"
                                        variant="contained"
                                        color="inherit"
                                        sx={{
                                            pr: .7,
                                            pl: .7,
                                            fontSize: 10,
                                            bgcolor: appTheme.color12,
                                            "&:hover": {
                                                bgcolor: appTheme.color12
                                            },
                                            color: appTheme.color0,
                                            focusVisible: false,
                                        }}
                                    >
                                        <Typography component="span" variant="caption" sx={{textTransform:"capitalize"}}>View All</Typography>

                                    </Button>

                                </Box>
                           </div>
        <Box>
      </Box>
      {
        !!websiteData && 
        
        <div className='createNewIdCard'>
         {
              websiteData.map((item, index) => {
                if(colorIdx.current > 3){
                  colorIdx.current = 0;
                }
                const color = getRandomColor(colorIdx.current);
                colorIdx.current++;
                
                return <div key={item.id} className='createNewIdCardContainer' 
                style={{cursor:'pointer'}}
                onClick={() => {
                  if (!item.is_create) {
                    setCreateIdData(item)
                    navigate('/createId')
                  } else {
                    handleClick('Site Under Maintenance!');
                  }
                }} >
                  <div
                    className='imgContainer'
                    style={{border:`1px solid ${color.dark}`,  background: `${color.light}`, opacity:item.is_create?'0.5':'1'}}>
                      {
                        item.is_create?<div style={{display:'flex', justifyContent:'center', padding:'0.5rem 0 0 0'}}><ConstructionOutlinedIcon fontSize='small'/> Maintenance</div> :''
                      }
                    <img
                      src={!!getImgSrc(item?.logo_url) ? `${getImgSrc(item.logo_url)}` : require('../img/no-pictures.png')}
                      alt={item.name}
                      loading="lazy"
                    />
                    {item.name}
                  </div>
                  <div className='createButton' id={'firstIdCreation' + index} style={{background: `${color.dark}`, cursor:'pointer'}}>
                    <span>Create Id</span> &nbsp;<TrendingFlatIcon fontSize='small' />
                  </div>
                </div>
                
              })
            }
        
        </div>
      }
        <Stack spacing={2}>
          <Button
            // startIcon={<AddCircleSharpIcon />}
            sx={{
              textTransform:'capitalize',
              color: appTheme.color0, bgcolor: appTheme.color12,
              "&:hover": {
                bgcolor: appTheme.color12
              },
              focusVisible: false,
              pb: 1, pt: 1
            }}
            onClick={() => {
              navigate('/ids?index=1')
            }}
          >
            View All
          </Button>
        </Stack>
    </>





  );
}
const TitlebarImageList = React.memo(TitlebarImageListScreen)
export default TitlebarImageList;