import React from 'react';
import {
    Button,
    Stack,
    Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import constant from '../constants/Constatnt';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { appTheme } from '../theme/colorTheme';

const NotificationsDetailScreen = (props) => {
    const navigateToTransactionsDetails = (selectedId) => {
        props.viewTransactionDetails(selectedId)
    }

    const transactionList = {}
    transactionList["transactionRequest"] =
        props?.itemList?.transaction?.transactionable_type === constant.transactionType.depositWallet || props?.itemList?.transaction?.transactionable_type === constant.transactionType.withdrawWallet ? 'WalletRequest' :
            props?.itemList?.transaction?.transactionable_type === constant.transactionType.depositId || props?.itemList?.transaction?.transactionable_type === constant.transactionType.withdrawId ? 'ExistingIdRequest' :
                props?.itemList?.transaction?.transactionable_type === constant.transactionType.changeIdPasswordRequest ? 'ChangePasswordRequest' : 'CreateIdRequest';

    transactionList["brandName"] = transactionList?.transactionRequest === 'CreateIdRequest' ? props?.itemList?.transaction?.transactionable.website?.name :
        transactionList?.transactionRequest === 'ExistingIdRequest' ? props?.itemList?.transaction?.transactionable.user_website?.website.name :
            transactionList?.transactionRequest === 'ChangePasswordRequest' ? props?.itemList?.transaction?.transactionable.user_website?.website.name : '';

    transactionList["accessUrl"] = transactionList?.transactionRequest === 'CreateIdRequest' ? props?.itemList?.transaction?.transactionable.website?.access_url :
        transactionList?.transactionRequest === 'ExistingIdRequest' ? props?.itemList?.transaction?.transactionable.user_website?.website.access_url :
            transactionList?.transactionRequest === 'ChangePasswordRequest' ? props?.itemList?.transaction?.transactionable.user_website?.website.access_url : '';
    transactionList["requestedAmount"] = props?.itemList?.transaction?.transactionable?.requested_amount;
    return (
        <React.Fragment>
            <Stack>
                <Typography variant="caption" gutterBottom sx={{ textAlign: 'center', color: appTheme.color1 }}>
                    {props?.itemList?.transaction?.reason_remarks}
                </Typography>
            </Stack>
            <Stack
                sx={{ color: appTheme.color1, bgcolor: appTheme.color0, padding: 1, pb: 1, }}
                direction="row"
                alignItems="center"
                justifyContent="space-between"
            >
                <Box>
                    <Typography variant="caption" gutterBottom fontSize={13}>
                        Details
                    </Typography>

                </Box>
                <Box>
                    <Button
                        onClick={() => {
                            navigateToTransactionsDetails(props?.itemList?.transaction_id)
                        }}

                        size="small"
                        variant="contained"
                        color="inherit"
                        sx={{
                            textTransform:"capitalize",
                            pr: .7,
                            pl: .7,
                            bgcolor: appTheme.color12,
                            "&:hover": {
                                bgcolor: appTheme.color12
                            },
                            color: appTheme.color0,
                            mr: 0,
                            focusVisible: false,
                        }}
                    >
                        <Typography component="span" variant="caption" fontSize={10}>View More</Typography>

                    </Button>
                </Box>
            </Stack>
            <Stack
                sx={{ color: appTheme.color1, bgcolor: appTheme.color0, padding: 1 }}
            >
                { transactionList?.brandName && 
                    <Box sx={{ mb: .3 }}>
                    <Typography variant="caption" component="div" sx={{ fontSize: 10, color: appTheme.color6 }}>
                        Site Name
                    </Typography>
                    <Typography variant="caption" component="div" gutterBottom>
                        {transactionList?.brandName}
                    </Typography>
                </Box>
                }
               { transactionList?.accessUrl &&
                 <Box sx={{ mb: .3 }}>
                 <Typography variant="caption" component="div" sx={{ fontSize: 10, color: appTheme.color6 }}>
                     Site URL
                 </Typography>
                 <Typography variant="caption" component="div" gutterBottom>
                     <a href={`https://${transactionList?.accessUrl}`} target='blank' style={{ color: `white`, cursor: 'pointer', textDecoration: 'none' }}>
                         {transactionList?.accessUrl} <OpenInNewIcon fontSize="small" sx={{ fontSize: 14, verticalAlign: 'middle' }} />
                     </a>

                 </Typography>
             </Box>
               }
                {!!transactionList?.requestedAmount && <Box>
                    <Typography variant="caption" component="div" sx={{ fontSize: 10, color: appTheme.color6 }}>
                        Requested Amount
                    </Typography>
                    <Typography variant="caption" gutterBottom component="div">
                        <img alt='coins' src={require("../img/coins-icon.png")} width="15" style={{ verticalAlign: 'middle' }} /> {transactionList?.requestedAmount}
                    </Typography>
                </Box>}

            </Stack>
        </React.Fragment>
    );
}
const NotificationsDetail = React.memo(NotificationsDetailScreen);
export default NotificationsDetail;
