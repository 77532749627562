import React, { useEffect } from 'react'
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Button from '@mui/material/Button';
import { appTheme } from '../../theme/colorTheme';
import { useNavigate } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';
import NotFound from '../notFound/notFound';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import paymentSuccess from '../../img/payment-success.svg';
import paymentFailure from '../../img/payment-failure.svg';
import paymentPending from '../../img/payment-pending.svg';
import CancelIcon from '@mui/icons-material/Cancel';
import Skeleton from '@mui/material/Skeleton';
import InfoIcon from '@mui/icons-material/Info';
import axiosService from '../../axios-api-kit/axiosService';
import constant from '../../constants/Constatnt';
import { AuthContext } from '../../context/AuthProvider';
const TransactionStatusScreen = () => {
  let navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [paymentStatus, setPaymentStatus] = React.useState(null);
  const { getUpdatedUser } = React.useContext(AuthContext);
  const client_txn_id = searchParams.get("client_txn_id");
  const txn_id = searchParams.get("txn_id");
  const txn_id_number = searchParams.get("txn_id_number");
  const status = searchParams.get("status");
  const checkGetwayOrderStatus = () => {
    if (client_txn_id && txn_id) {
      const payload = new URLSearchParams()
      payload.append('client_txn_id', client_txn_id)
      payload.append('txn_id', txn_id)
      axiosService.check_upi_gateway_order_status(payload).then((resp) => {
        if (resp?.data?.status) {
          setPaymentStatus(resp?.data?.status)
        }else {
          setPaymentStatus(constant.transactionStatus.error)
        }
      })
      .catch((error) => {
        setPaymentStatus(constant.transactionStatus.error)
      })
    }else if(txn_id_number && status){
      setPaymentStatus(status);
    }
  }
  useEffect(() => {
    checkGetwayOrderStatus();
  },[]);

  if ((client_txn_id && txn_id) || (txn_id_number && status)) {
    return (
      <Container
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '70vh',
          paddingLeft: 2,
          paddingRight: 2,
          color: appTheme.color1,
          width: '100%',
        }}
      >

        <Box>
          <Stack sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
            {
              paymentStatus === null &&
              <>
                <Skeleton variant="rounded" width={250} height={150} sx={{ backgroundColor: appTheme.color11, marginBottom: 2 }} />
                <Skeleton variant="circular" width={50} height={50} sx={{ backgroundColor: appTheme.color11, marginBottom: 1 }} />
                <Skeleton variant="text" sx={{ fontSize: '1rem', width: '100%', backgroundColor: appTheme.color11, marginBottom: 1 }} />
                <Skeleton variant="text" sx={{ fontSize: '1rem', width: '100%', backgroundColor: appTheme.color11, marginBottom: 1 }} />
                <Skeleton variant="text" sx={{ fontSize: '1rem', width: '100%', backgroundColor: appTheme.color11, marginBottom: 1 }} />
                <Skeleton variant="button" width={130} height={40} sx={{ backgroundColor: appTheme.color11, marginBottom: 1 }} />

              </>
            }
            {
              paymentStatus === constant.transactionStatus.success &&
              <>
                <img src={paymentSuccess} style={{ width: '70%' }}  loading="lazy" alt='success-payment' />
                <CheckCircleIcon sx={{ fontSize: 50, color: appTheme.color13 }} />
                <Typography variant="h6" gutterBottom>Payment successful!</Typography>
                {txn_id && <Typography variant="body1" gutterBottom>Transaction ID: <strong>{txn_id}</strong></Typography>}
                {txn_id_number && <Typography variant="body1" gutterBottom>Transaction ID: <strong>{txn_id_number}</strong></Typography>}
                <Typography variant="body1" sx={{ textAlign: 'center' }} gutterBottom>We have received your payment.</Typography>
                <Button variant="outlined" sx={{ border: `1px solid ${appTheme.color13}`, color: appTheme.color1, mt: 1 }} onClick={() => {
                  getUpdatedUser();
                  setTimeout(() => navigate('/'), 1000);
                }}>Back to home</Button>
              </>
            }
            {
              (paymentStatus === constant.transactionStatus.pending || paymentStatus === constant.transactionStatus.created) &&
              <>
                <img src={paymentPending} style={{ width: '70%' }}  loading="lazy" alt='pending-payment'/>
                <InfoIcon sx={{ fontSize: 50, color: appTheme.color18 }} />
                <Typography variant="h6" gutterBottom>Payment pending!</Typography>
                {txn_id && <Typography variant="body1" gutterBottom>Transaction ID: <strong>{txn_id}</strong></Typography>}
                {txn_id_number && <Typography variant="body1" gutterBottom>Transaction ID: <strong>{txn_id_number}</strong></Typography>}
                <Typography variant="body1" gutterBottom sx={{ textAlign: 'center' }}>Your payment is currently pending processing. Please note that it may take few minutes for the payment to be processed and reflected in your account. Thank you for your patience.</Typography>
                <Button variant="outlined" sx={{ border: `1px solid ${appTheme.color18}`, color: appTheme.color1, mt: 1 }} onClick={() => navigate('/')}>Back to home</Button>
              </>
            }
            {
              (paymentStatus === constant.transactionStatus.failure || paymentStatus === constant.transactionStatus.error) &&
              <>
                <img src={paymentFailure} style={{ width: '70%' }}  loading="lazy" alt='failed-payment'/>
                <CancelIcon sx={{ fontSize: 50, color: appTheme.color16 }} />
                <Typography variant="h6" gutterBottom>Payment failed</Typography>
                {txn_id && <Typography variant="body1" gutterBottom>Transaction ID: <strong>{txn_id}</strong></Typography>}
                {txn_id_number && <Typography variant="body1" gutterBottom>Transaction ID: <strong>{txn_id_number}</strong></Typography>}
                <Typography variant="body1" gutterBottom sx={{ textAlign: 'center' }}>Something went wrong we couldn't process your payment. contact our support if you have lost money.</Typography>
                <Button variant="outlined" sx={{ border: `1px solid ${appTheme.color16}`, color: appTheme.color1, mt: 1 }} onClick={() => navigate('/')}>Back to home</Button>
              </>
            }
            {
             (paymentStatus !== null && 
              paymentStatus !== constant.transactionStatus.success &&
              paymentStatus !== constant.transactionStatus.pending &&
              paymentStatus !== constant.transactionStatus.failure &&
              paymentStatus !== constant.transactionStatus.created &&
              paymentStatus !== constant.transactionStatus.error) && 
              
              <>
               <img src={paymentFailure} style={{ width: '70%' }} loading="lazy" alt='failed-payment'/>
                <CancelIcon sx={{ fontSize: 50, color: appTheme.color16 }} />
                <Typography variant="h6" gutterBottom>Payment cancelled</Typography>
                {txn_id && <Typography variant="body1" gutterBottom>Transaction ID: <strong>{txn_id}</strong></Typography>}
                {txn_id_number && <Typography variant="body1" gutterBottom>Transaction ID: <strong>{txn_id_number}</strong></Typography>}
                <Typography variant="body1" gutterBottom sx={{ textAlign: 'center' }}>Payment has been cancelled by the user.</Typography>
                <Button variant="outlined" sx={{ border: `1px solid ${appTheme.color16}`, color: appTheme.color1, mt: 1 }} onClick={() => navigate('/')}>Back to home</Button>
              </>
            }
          </Stack>
        </Box>
      </Container>
    )
  }
  return (
    <NotFound />
  )
}
const TransactionStatus = React.memo(TransactionStatusScreen)
export default TransactionStatus