import React from "react";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { CssBaseline, ThemeProvider, Container } from "@mui/material";
import Slide from "@mui/material/Slide";
import { DialogContent } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import theme from "../../theme/theme";
import { appTheme } from "../../theme/colorTheme";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const FullCookiesDialogScreen = () => {
    let navigate = useNavigate();
    const location = useLocation();
    const [openDialog, setOpenDialog] = React.useState(true);

    const handleDialogClose = () => {
        if(location.key === 'default'){
            navigate('/');
        }else{
            navigate(-1);
        }
        setOpenDialog(false);
    };
    React.useEffect(() => {
        if (location.key === 'default') {
          navigate('/');
        }
      }, [])

    return (
        <>
            <Dialog
                fullScreen
                open={openDialog}
                sx={{ maxWidth: "600px", margin: '0 auto' }}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: "relative", bgcolor: appTheme.color0 }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={() => handleDialogClose()}
                            aria-label="close"
                        >
                            <ArrowBackIosIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="body1" component="div">
                            Cookie Policy
                        </Typography>
                    </Toolbar>
                </AppBar>
                <DialogContent sx={{ backgroundColor: appTheme.color0, padding: 0, pb: 1, }}>
                    <ThemeProvider theme={theme}>
                        <CssBaseline />
                        <Container sx={{
                            color: appTheme.color1,
                            mt: 2
                        }}>
                            <Typography variant="body2" component="div" color={appTheme.color6}>
                            Tracking Technologies and Cookies
                            </Typography>
                            <Typography variant="caption" component="div" >
                              We use Cookies and similar tracking technologies to track the activity on Our Service and store certain information. Tracking technologies used are beacons, tags, and scripts to collect and track information and to improve and analyze Our Service. The technologies We use may include:
                            </Typography>
                            <ul>
                                <li>
                                    <Typography variant="caption" component="div" >
                                        Cookies or Browser Cookies. A cookie is a small file placed on Your Device. You can instruct Your browser to refuse all Cookies or to indicate when a Cookie is being sent. However, if You do not accept Cookies, You may not be able to use some parts of our Service. Unless you have adjusted Your browser setting so that it will refuse Cookies, our Service may use Cookies.
                                    </Typography>
                                </li>
                                <li>
                                    <Typography variant="caption" component="div">
                                        Flash Cookies. Certain features of our Service may use local stored objects (or Flash Cookies) to collect and store information about Your preferences or Your activity on our Service. Flash Cookies are not managed by the same browser settings as those used for Browser Cookies. For more information on how You can delete Flash Cookies, please read "Where can I change the settings for disabling, or deleting local shared objects?".
                                    </Typography>
                                </li>
                                <li>
                                    <Typography variant="caption" component="div" >
                                        Web Beacons. Certain sections of our Service and our emails may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit the Company, for example, to count users who have visited those pages or opened an email and for other related website statistics (for example, recording the popularity of a certain section and verifying system and server integrity).
                                    </Typography>
                                </li>
                            </ul>

                            <Typography variant="caption" component="div"  >
                            Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies remain on Your personal computer or mobile device when You go offline, while Session Cookies are deleted as soon as You close Your web browser.
                            </Typography>
                            <Typography variant="caption" component="div"  >
                            We use both Session and Persistent Cookies for the purposes set out below:
                                </Typography>
                                <ul>
                                <li>
                                    <Typography variant="caption" component="div" >
                                        Necessary / Essential Cookies<br/>

                                        Type: Session Cookies<br/>

                                        Administered by: Us<br/>

                                        Purpose: These Cookies are essential to provide You with services available through the Website and to enable You to use some of its features. They help to authenticate users and prevent fraudulent use of user accounts. Without these Cookies, the services that You have asked for cannot be provided, and We only use these Cookies to provide You with those services.
                                    </Typography>
                                </li>
                                <li>
                                    <Typography variant="caption" component="div" >
                                        Cookies Policy / Notice Acceptance Cookies<br/>

                                        Type: Persistent Cookies<br/>

                                        Administered by: Us<br/>

                                        Purpose: These Cookies identify if users have accepted the use of cookies on the Website.
                                    </Typography>
                                </li>
                                <li>
                                    <Typography variant="caption" component="div"  >
                                        Functionality Cookies<br/>

                                        Type: Persistent Cookies<br/>

                                        Administered by: Us<br/>

                                        Purpose: These Cookies allow us to remember choices You make when You use the Website, such as remembering your login details or language preference. The purpose of these Cookies is to provide You with a more personal experience and to avoid You having to re-enter your preferences every time You use the Website.
                                    </Typography>
                                </li>
                            </ul>
                            <Typography variant="caption" component="div" >
                            For more information about the cookies we use and your choices regarding cookies, please visit our Cookies Policy or the Cookies section of our Privacy Policy.
                            </Typography>
                        </Container>
                        </ThemeProvider>

                </DialogContent>
            </Dialog>
        </>
    );
};
const CookiesDailog = React.memo(FullCookiesDialogScreen);
export default CookiesDailog;
