import React from "react";
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Stack from '@mui/material/Stack';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import { CssBaseline, ThemeProvider } from "@mui/material";
import theme from "../../theme/theme";
import { Container, Box } from "@mui/system";
import CreateIdsAccordian from "../../layouts/CreateIdsAccordian";
import MyIdDetailCard from "../../layouts/MyIdsDetail";
import { localStorageKey } from "../../constants/LocalStorageKey";
import { appTheme } from "../../theme/colorTheme";
import { useLocation, useNavigate } from "react-router-dom";
import { speedDialActionKey } from "../../constants/SpeedDialKey";
import { getWhatsAppUrl } from "../../constants/Helper";
import { useSelector } from "react-redux";
import PaymentVideo from "../../layouts/PaymentVideo";
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { ReactComponent as SupportIcon } from '../../img/help-desk.svg';
import SpeedDialComponent from "../../layouts/SpeedDial";

const speedDialActions = [
  { icon: <WhatsAppIcon />, name: 'Customer support?', action: speedDialActionKey.CUSTOMERSUPPORT },
  { icon: <WhatsAppIcon />, name: 'Deposit issue?', action: speedDialActionKey.DEPOSITISSUE },
  { icon: <WhatsAppIcon />, name: 'Withdraw issue?', action:speedDialActionKey.WITHDRAWISSUE },
  { icon: <OndemandVideoIcon />, name: 'How to pay video?', action: speedDialActionKey.HOWTOPAY },
];
const actionIconSize = 40;
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ mt: 2 }}>
          <Typography component="span">{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}
const Ids = () => {
  let navigate = useNavigate();
  const location = useLocation();
  const [openPaymentVideo, setOpenPaymentVideo] = React.useState(false);
  const { deposit_number, withdraw_number } = useSelector(store => store?.app?.paymentHelplineData)
  const { content } = useSelector(store => store?.app?.commonHelpline);
  const queryParams = new URLSearchParams(window.location.search);
  const index =
    !!queryParams.get('index') ? +queryParams.get('index') : !!sessionStorage.getItem(localStorageKey.SETIDSTABSTATE) ?
      +sessionStorage.getItem(localStorageKey.SETIDSTABSTATE) : 1;

  const idTheme = useTheme();
  const [value, setValue] = React.useState(index);

  const closeVideo = () => {
    setOpenPaymentVideo(false);
    localStorage.setItem(localStorageKey.USER_LOGGED_IN_TIME, new Date());
  }


  const openSppedDialAction = (action) => {
    // eslint-disable-next-line default-case
    switch (action) {
      case speedDialActionKey.CUSTOMERSUPPORT:
        window.open(getWhatsAppUrl(content));
       break;
      case speedDialActionKey.DEPOSITISSUE:
        window.open(getWhatsAppUrl(deposit_number));
       break;
      case speedDialActionKey.WITHDRAWISSUE:
        window.open(getWhatsAppUrl(withdraw_number));
       break;
      case speedDialActionKey.HOWTOPAY:
       if(!openPaymentVideo){
         setOpenPaymentVideo(true)
       }
       break;
    }
   }

  const handleChange = (event, newValue) => {
    if(!!queryParams.get('index')){
      navigate(`/ids?index=${newValue}`)
    }
    setValue(newValue);
    sessionStorage.setItem(localStorageKey.SETIDSTABSTATE, newValue)
  };

  React.useEffect(() => {
    if (location.key === 'default') {
      navigate('/');
    }
  }, [])

  // const handleChangeIndex = (index) => {
  //   setValue(index);
  // };
  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Container>
          <Box sx={{ color: appTheme.color1, width: '100%', position: 'relative' }}>
            <AppBar position="sticky" sx={{ bgcolor: appTheme.color0, color: appTheme.color1, border: 0 }}>
              <Tabs
                value={value}
                onChange={handleChange}
                textColor="inherit"
                variant="fullWidth"
                aria-label="full width tabs example"
                TabIndicatorProps={{
                  style: {
                    backgroundColor: appTheme.color12,
                  },
                }}
              >
                <Tab label="My Ids" {...a11yProps(0)} />
                <Tab label="Create ID" {...a11yProps(1)} />
              </Tabs>
            </AppBar>
            <Stack
              axis={idTheme.direction === 'rtl' ? 'x-reverse' : 'x'}
              index={value}

            >
              <TabPanel value={value} index={0} dir={idTheme.direction}>
                <MyIdDetailCard />
              </TabPanel>
              <TabPanel value={value} index={1} dir={idTheme.direction}>
                <CreateIdsAccordian />
              </TabPanel>
            </Stack>
          </Box>
        </Container>
        {
            <SpeedDialComponent cbSpeedial={openSppedDialAction} direction="up" 
            position={{ position: 'fixed', bottom: 80, right: 15 }} 
            actions={speedDialActions}
            actionIcon={<SupportIcon style={{ width: actionIconSize, height: actionIconSize }} />}
            actionIconSize={actionIconSize} />
        }
        {
        openPaymentVideo && <PaymentVideo closeVideo={closeVideo} />
      }
      </ThemeProvider>
    </React.Fragment>
  )
}



export default Ids;