import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { appTheme } from '../theme/colorTheme';
import CloseIcon from '@mui/icons-material/Close';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { localStorageKey } from '../constants/LocalStorageKey';
import { getRefreralWhatsAppUrl } from '../constants/Helper';
import useMediaQuery from '@mui/material/useMediaQuery';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});






const ReferAppModal = (props) => {
    const extraSmallDevice = useMediaQuery('(max-width:330px)');
    const buttonStyling = {display:'flex', alignItems:'center', justifyContent:'center',
    position: 'absolute',
    padding: `${extraSmallDevice ? '12px 26px' : '12px 0'}`,
    top: 10, zIndex: 99999, border: 0, 
    left:'20%', right:'20%'};
    const handleClose = () => {
        props.setOpenRefer(false)
    }

    return (
        <>
            <Dialog
                sx={{
                    "& .MuiPaper-root": {
                        backgroundColor: 'transparent'
                    },

                }}
                open={props.openRefer}
                TransitionComponent={Transition}
                keepMounted
                aria-describedby="alert-dialog-slide-description"
            >

                <div style={{
                    border: '2px solid' + appTheme.color3,
                }}>
                    <img src={require(`../img/refer.jpg`)} alt="Refer App" style={{ width: "100%", padding: 0, marginBottom: '-7px' }} />

                </div>
                <button style={{ position: 'absolute', top: 0, zIndex: 99999, border: 0, right: 0 }} className='modalClose'
                onClick={() =>{
                    handleClose();
                    localStorage.setItem(localStorageKey.ISREFEREMODALEOPEN, JSON.stringify(false));
                }}>
                    <CloseIcon />
                </button>
                <button className="button-3d" style={buttonStyling} onClick={() => {
                    window.open(getRefreralWhatsAppUrl());
                    localStorage.setItem(localStorageKey.ISREFEREMODALEOPEN, JSON.stringify(false));
                    handleClose();
                }}>
                <WhatsAppIcon sx={{ color: '#22C55E' }}/>
                    <span>&nbsp;Invite via whatsapp</span>
                </button>
            </Dialog>
        </>
    );
}

export default ReferAppModal;
