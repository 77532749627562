import React, { useEffect, useRef } from "react";
import {
  CssBaseline,
  Skeleton,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import { Box, Container } from "@mui/system";
import theme from "../../theme/theme";
import CarouselSlider from "../../layouts/Carousel";
import axiosService from "../../axios-api-kit/axiosService";
import { useNavigate } from "react-router-dom";
import { localStorageKey } from "../../constants/LocalStorageKey";
import MyIdHomeDetailCard from "../../layouts/myIdsHome";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { appTheme } from "../../theme/colorTheme";
import Marquee from "../../layouts/Marquee";
import DownloadAppModal from "../../layouts/DownloadAppModal";
import { getHoursDifference, getWhatsAppUrl, isMobileDeviceAndroid } from "../../constants/Helper";
import { setIsIntersecting } from "../../utils/headerIntersectionSlice";
import ConstructionOutlinedIcon from '@mui/icons-material/ConstructionOutlined';
import SnackBar from "../../layouts/SnackBar";
import SpeedDialComponent from "../../layouts/SpeedDial";
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import { speedDialActionKey } from "../../constants/SpeedDialKey";
import { ReactComponent as SupportIcon } from '../../img/help-desk.svg';
import PaymentVideo from "../../layouts/PaymentVideo";
import constant from "../../constants/Constatnt";
import { AuthContext } from "../../context/AuthProvider";
import ReferAppModal from "../../layouts/ReferModal";

const speedDialActions = [
  { icon: <WhatsAppIcon />, name: 'Customer support?', action: speedDialActionKey.CUSTOMERSUPPORT },
  { icon: <WhatsAppIcon />, name: 'Deposit issue?', action: speedDialActionKey.DEPOSITISSUE },
  { icon: <WhatsAppIcon />, name: 'Withdraw issue?', action:speedDialActionKey.WITHDRAWISSUE },
  { icon: <OndemandVideoIcon />, name: 'How to pay video?', action: speedDialActionKey.HOWTOPAY },
];
const actionIconSize = 40;
const Home = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {joyTour } = React.useContext(AuthContext);
  const elementRef = useRef(null);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [snackBarMessage, setSnackBarMessage] = React.useState('');
  const { wallet_balance } = useSelector(store => store?.app?.userInfo);
  const { withdraw_status, hold_withdrawal_remarks } = useSelector(store => store?.app?.withdrawalStatus);
  const { deposit_number, withdraw_number } = useSelector(store => store?.app?.paymentHelplineData)
  const { content } = useSelector(store => store?.app?.commonHelpline);
  const isAppDownloadSnackbarOpen = useSelector(store => store?.app?.isAppDownloadSnackbarOpen);
  const { data: announcementNews } = useSelector(store => store?.app?.newsData);
  const [bannerData, setBannerData] = React.useState(null);
  const [openInfo, setOpenInfo] = React.useState(false);
  const [openPaymentVideo, setOpenPaymentVideo] = React.useState(false);
  const [openRefer, setOpenRefer] = React.useState(false);
  const isDownloadAppsSnackbarOpen = !localStorage.getItem(localStorageKey.PUSHID)  && isMobileDeviceAndroid() && localStorage.getItem(localStorageKey.ISDOWNLOADAPPSNACKBAROPEN) === null
  && !!JSON.stringify(localStorage.getItem(localStorageKey.ISDOWNLOADAPPSNACKBAROPEN));
  let options = {
    root: null,
    rootMargin: "0px",
    threshold: 0.75,
  };

  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      dispatch(setIsIntersecting(entry.isIntersecting))
    })
  }, options)

  const getBannerData = React.useCallback(() => {
    axiosService.getBanners().then((resp) => {
      setBannerData(resp.data);
    }).catch((error) => {
    })
  }, [])
  

  const closeVideo = () => {
    setOpenPaymentVideo(false);
    localStorage.setItem(localStorageKey.USER_LOGGED_IN_TIME, new Date());
  }

  const openSppedDialAction = (action) => {
   // eslint-disable-next-line default-case
   switch (action) {
     case speedDialActionKey.CUSTOMERSUPPORT:
       window.open(getWhatsAppUrl(content));
      break;
     case speedDialActionKey.DEPOSITISSUE:
       window.open(getWhatsAppUrl(deposit_number));
      break;
     case speedDialActionKey.WITHDRAWISSUE:
       window.open(getWhatsAppUrl(withdraw_number));
      break;
     case speedDialActionKey.HOWTOPAY:
      if(!openPaymentVideo){
        setOpenPaymentVideo(true)
      }
      break;
   }
  }

  useEffect(() => {
    const userLoggedTime = localStorage.getItem(localStorageKey.USER_LOGGED_IN_TIME);
    if (!!userLoggedTime) {
      const timeDiffrence = getHoursDifference(new Date(userLoggedTime), new Date());
      if (timeDiffrence >= constant.maxHoursToShowPaymentVideo) {
        setOpenPaymentVideo(true);
      }
    } else {
      setOpenPaymentVideo(true);
    }


    sessionStorage.removeItem(localStorageKey.SETIDSTABSTATE);
    getBannerData();
  }, []);

  useEffect(() => {
    if(!!JSON.parse(sessionStorage.getItem(localStorageKey.SHOULDOPENREFERMODAL))){
      setOpenRefer(true);

    }
    const timer = setTimeout(() => {
      setOpenInfo(true);
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    observer.observe(elementRef.current);
    return () => {
      observer.disconnect();
      dispatch(setIsIntersecting(true));
    }
  }, [])
  return (
    <React.Fragment>
      <SnackBar
        setOpen={setOpenSnackbar}
        open={openSnackbar}
        message={snackBarMessage}
      />
      {
        joyTour.status === 'CLOSED' &&
        !localStorage.getItem(localStorageKey.PUSHID) && isMobileDeviceAndroid() && (openInfo &&
          localStorage.getItem(localStorageKey.ISDOWNLOADAPKPOPUPOPEN) === null
          && !!JSON.stringify(localStorage.getItem(localStorageKey.ISDOWNLOADAPKPOPUPOPEN))) && <DownloadAppModal
          openInfo={openInfo}
          setOpenInfo={setOpenInfo} />
      }
      {
        (openRefer &&
          localStorage.getItem(localStorageKey.ISREFEREMODALEOPEN) === null
          && !!JSON.stringify(localStorage.getItem(localStorageKey.ISREFEREMODALEOPEN))) && <ReferAppModal
          openRefer={openRefer}
          setOpenRefer={setOpenRefer} />
      }
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Container sx={{ color: appTheme.color1, paddingBottom: 2, marginTop: isDownloadAppsSnackbarOpen && !isAppDownloadSnackbarOpen && joyTour.status === 'CLOSED' ? 12 : 0 }}>
          <Box ref={elementRef} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', background: "linear-gradient(45deg, #1a1a1a, #1a1a1a)", boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)", borderRadius: 2, pl: 2, pb: 1, pr: .5 }}>
            <Box sx={{ pt: 2 }} id="yourWallet">
              <Typography variant="h6" fontSize={15} fontWeight={"bold"} display="block" gutterBottom>
                My Wallet
              </Typography>
              <Typography variant="h5" fontWeight={"bold"} display="block" gutterBottom>
                <span style={{ color: appTheme.color6 }}>₹</span><span style={{ color: appTheme.color12 }}>{wallet_balance}</span>
              </Typography>
            </Box>
            <Box sx={{ display: 'flex' }}>
              <Box
              id="depositWallet"
                sx={{ textAlign: 'center', textDecoration: 'none', color: appTheme.color1, cursor: 'pointer' }}
                onClick={() => {
                  navigate('/walletDeposit');
                }}
              >
                <img
                  src={require(`../../img/deposit_icon.png`)}
                  width="80"
                  alt="logo"
                  loading="lazy"
                />
                <Typography variant="caption" display="flex" gutterBottom fontSize={11} sx={{ mt: -3, justifyContent: 'center' }}>
                  Deposit
                </Typography>
              </Box>
              <Box
               id="withdrawWallet"
                sx={{ textAlign: 'center', textDecoration: 'none', color: appTheme.color1, cursor: 'pointer', opacity: withdraw_status === 'off' ? 0.4 : 1 }}
                onClick={() => {
                  if (withdraw_status === 'off') {
                    setSnackBarMessage(hold_withdrawal_remarks || 'Withdraw wallet on hold, we are under maintenance!');
                    setOpenSnackbar(true);
                  } else {
                    navigate('/withdrawWallet')
                  }
                }} >
                <img
                  src={require(`../../img/withdraw_icon.png`)}
                  width="80"
                  alt="logo"
                  loading="lazy"
                />
                <Typography variant="caption" display="flex" gutterBottom fontSize={11} sx={{ mt: -3, justifyContent: 'center' }}>
                  {withdraw_status === 'off' && <ConstructionOutlinedIcon fontSize="small" />}
                  Withdraw
                </Typography>
              </Box>

            </Box>
          </Box>
          <Box sx={{ mt: 1 }}>

            {
              !!bannerData ? <CarouselSlider data={bannerData} navigate={navigate} /> :
                <Box sx={{ width: '100%' }}>
                  <Skeleton sx={{ backgroundColor: appTheme.color11 }} />
                  <Skeleton animation="wave" sx={{ backgroundColor: appTheme.color11 }} />
                  <Skeleton sx={{ backgroundColor: appTheme.color11 }} />
                  <Skeleton animation="wave" sx={{ backgroundColor: appTheme.color11 }} />
                  <Skeleton sx={{ backgroundColor: appTheme.color11 }} />
                  <Skeleton animation="wave" sx={{ backgroundColor: appTheme.color11 }} />
                  <Skeleton sx={{ backgroundColor: appTheme.color11 }} />
                  <Skeleton animation="wave" sx={{ backgroundColor: appTheme.color11 }} />
                </Box>
            }

          </Box>
          <Marquee anoucmentText={announcementNews} />
          <Box>
            <MyIdHomeDetailCard />
          </Box>

          {/* <Box>
            <Stack sx={{
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              mb: 2,
              mt: 2
            }}>
              {
                !!content && !!title ?
                  <>
                    <Typography variant="caption" display="block" gutterBottom sx={{ color: appTheme.color12 }}>
                      Tips & Anouncements
                    </Typography>
                    <Typography variant="caption" display="block" gutterBottom>
                      {title}<ReactWhatsapp number={`${content}`} message="" style={{
                        backgroundColor: appTheme.color12,
                        border: 0, borderRadius: 50, color: `black`, padding: '5px 10px', fontSize: 12, cursor: 'pointer'
                      }}>
                        <WhatsAppIcon fontSize="small" sx={{ verticalAlign: 'middle', fontSize: 18 }} /> {content}
                      </ReactWhatsapp>
                    </Typography>
                  </> : <Box sx={{ width: '100%' }}>
                    <Skeleton sx={{ backgroundColor: appTheme.color11 }} />
                    <Skeleton animation="wave" sx={{ backgroundColor: appTheme.color11 }} />
                    <Skeleton sx={{ backgroundColor: appTheme.color11 }} />
                  </Box>
              }
            </Stack>
          </Box> */}
        </Container>
       {
        joyTour.status === 'CLOSED' && 
         <SpeedDialComponent cbSpeedial={openSppedDialAction} direction="up" 
         position={{ position: 'fixed', bottom: 80, right: 15 }} 
         actions={speedDialActions}
         actionIcon={<SupportIcon style={{ width: actionIconSize, height: actionIconSize }} />}
         actionIconSize={actionIconSize} />
       }
        {/* <Typography variant="caption" display="block" gutterBottom>
                      <ReactWhatsapp number={`${content}`} message="" style={{
                        position: 'fixed', right: 15, bottom: 80,
                        backgroundColor: '#0FC555',
                        border: 0, borderRadius: 100, color: appTheme.color1, padding: '10px', fontSize: 12, cursor: 'pointer'
                      }}>
                        <WhatsAppIcon fontSize="large" sx={{ verticalAlign: 'middle'}} />
                      </ReactWhatsapp>
                    </Typography> */}
      </ThemeProvider>
      {
        joyTour.status === 'CLOSED' && openPaymentVideo && <PaymentVideo closeVideo={closeVideo} />
      }

    </React.Fragment>
  );
};

export default Home;
